import * as Sentry from '@sentry/react'
import { HydratedRouter } from 'react-router/dom'

import { StrictMode, startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'

if (import.meta.env.PROD)
  Sentry.init({
    dsn: 'https://aa071e22781de9312794d1bd77008358@o4507328510754816.ingest.de.sentry.io/4507328535789648',
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      // eslint-disable-next-line import/namespace
      Sentry.browserTracingIntegration(),
      // eslint-disable-next-line import/namespace
      Sentry.replayIntegration(),
    ],
  })

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
    </StrictMode>,
  )
})
